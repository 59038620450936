import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ComodidadesSuperior() {
  return (
    <div className="my-4">
      <div className="container d-flex justify-content-center">
        <hr
          className="color-gris-claro-fondo "
          style={{ height: "2px", width: "720px" }}
        />
      </div>
      <div className="text-center">
        <p className=" tamaño-mas-grande peso-italic color-negro d-none d-lg-block">
          {" "}
          Comodidades y Servicios
        </p>
        <p className=" tamaño-grande peso-italic color-negro d-lg-none d-block">
          {" "}
          Comodidades y Servicios
        </p>
        <p className="d-none d-lg-block">
          <span className="text-center tamaño-grande peso-italic color-negro">
            Superior
          </span>
          <br />
          Habitaciones muy silenciosas, en piso superior. Comodidades de nivel
          superior, con las increíbles vistas del imponente paisaje de Tafí.
        </p>
        <p className="tamaño-chico  d-lg-none d-block">
          <span className="tamaño-chico peso-italic color-negro text-center">
            Superior
          </span>{" "}
          <br />
          Habitaciones muy silenciosas, en piso superior. Comodidades de nivel
          superior, con las increíbles vistas del imponente paisaje de Tafí.
        </p>
      </div>
      <div className="d-none d-lg-block">
        <p className="text-center tamaño-grande peso-italic color-negro">
          {" "}
          Descripción{" "}
        </p>
      </div>
      <div className="d-lg-none d-block">
        <p className="text-center tamaño-chico peso-italic color-negro">
          {" "}
          Descripción{" "}
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-sm-4 d-none d-lg-block">
          <ul className="list-unstyled text-center ">
            <li> Frigobar</li>
            <li> Salidas de baño para cada huésped</li>
            <li> Desayuno americano</li>
            <li>Wifi</li>
          </ul>
        </div>
        <div className="col-12 col-sm-4 tamaño-chico  d-lg-none d-block">
          <ul className="list-unstyled text-center ">
            <li> Aire acondicionado frío calor</li>
            <li> TV Led Smart con conexión a internet y Directv</li>
            <li> Caja de seguridad en habitación</li>
            <li>Servicio al cuarto</li>
          </ul>
        </div>
        <div className="col-12 col-sm-4 d-none d-lg-block">
          <ul className="list-unstyled text-center ">
            <li>Valijero</li>
            <li>Estacionamiento</li>
            <li>
              En el hotel: Servicio de restaurante en almuerzo y cena. SUM.
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-4 tamaño-chico  d-lg-none d-block">
          <ul className="list-unstyled text-center ">
          <li> Frigobar</li>
            <li> Salidas de baño para cada huésped</li>
            <li> Desayuno americano</li>
            <li>Wifi</li>
          </ul>
        </div>
        <div className="col-12 col-sm-4 d-none d-lg-block">
          <ul className="list-unstyled text-center ">
          <li> Aire acondicionado frío calor</li>
            <li> TV Led Smart con conexión a internet y Directv</li>
            <li> Caja de seguridad en habitación</li>
            <li>Servicio al cuarto</li>
          </ul>
        </div>
        <div className="col-12 col-sm-4 tamaño-chico  d-lg-none d-block">
          <ul className="list-unstyled text-center ">
          <li>Valijero</li>
            <li>Estacionamiento</li>
            <li>
              En el hotel: Servicio de restaurante en almuerzo y cena. SUM.
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-center my-5 d-none d-lg-flex">
        <Link to="/Reserva">
          <Button className="color-negro-fondo boton-reserva">
            <p className="text-white mb-0 peso-medium tamaño-medio mx-2">
              ¡QUIERO RESERVAR!
            </p>
          </Button>
        </Link>
      </div>
      <div className="d-flex justify-content-center my-2 tamaño-chico d-lg-none d-flex">
        <Link to="/Reserva">
          <Button className="color-negro-fondo boton-reserva">
            <p className="text-white mb-0 peso-medium mx-2">
              ¡QUIERO RESERVAR!
            </p>
          </Button>
        </Link>
      </div>
    </div>
  );
}
