export default function PoliticaContenido() {
  return (
    <div className="container">
      <h1 className="text-center my-5">Políticas generales del hotel</h1>
      <p>
        <b> Documentación: </b> todo huésped (menores y mayores de edad) deben
        presentar su DNI, pasaporte o cédula de identidad al momento del
        check-in y anotarlo en la ficha de registro.
      </p>
      <p>
        <b> Horarios: </b> Check-in (ingreso) desde las 14.00 hs; Check-out
        (salida) a las 11.00hs. El Ingreso más temprano o el egreso más tarde
        (hasta las 18 hs) tiene un costo mínimo del 50% de la tarifa rack y se
        encuentra sujeto a disponibilidad previa reserva.
      </p>
      <p>
        <b>Reservas y garantía:</b> toda reserva debe ser garantizada con
        tarjeta de crédito, efectivo o depósito bancario en concepto de seña por
        al menos el 50% del valor de la estadía. Todos los pasajeros deben
        presentar tarjeta de crédito en el check-in para garantizar los gastos
        extra en que pudiese incurrir durante su estadía; en caso de no disponer
        de tarjeta de crédito, el Hotel requerirá depósito de garantía en
        efectivo según la cantidad de noches, y/o se reserva el derecho de
        admisión y permanencia. Toda reserva tentativa que no fuese garantizada
        en un plazo de 48 hs. puede ser dada de baja por el hotel. Las reservas
        y cancelaciones se deben realizar vía mail a
        reservashoteltafi@gmail.com. El medio usado para garantía/seña no
        necesariamente debe ser el medio de pago, pudiendo el huésped optar
        entre todos los medios de pago aceptados (tarjeta de crédito/débito,
        efectivo, depósito o transferencia bancaria). El pago con cheques sólo
        se acepta previa solicitud de apertura de cuenta corriente con el hotel.
      </p>
      <p>
        <b>Pagos:</b> todos los pagos se realizan en moneda local (pesos). En
        caso que la reserva haya sido realizada con tarifa en dólares, se tomará
        el tipo de cambio Contado con Liqui para la venta del Banco Nación del
        momento de pago.
      </p>
      <p>
        <b>Cancelaciones:</b>
        <ul>
          <li>
            Reservas garantizadas con tarjeta de crédito: si se cancela con 5
            días o más de anticipación al horario de check-in, no hay ninguna
            penalidad. Si la cancelación fuese dentro de los 5 días previos al
            ingreso, se debitará de la tarjeta la primera noche de su estadía.
          </li>
          <li>
            Reservas prepagadas/garantizadas con efectivo o depósito bancario:
            si se cancela con 5 días o más de anticipación al horario de
            check-in, el pago puede quedar como saldo a favor para futuras
            estadías o ser devuelto en vouchers de otros servicios (gastronomía
            o spa, por ejemplo); o ser devuelta al cliente, reteniendo en ese
            caso el Hotel el 10% del valor total de la estadía en concepto de
            gastos administrativos. Si la cancelación fuese dentro de los 5 días
            previos al ingreso, se retendrá el total abonado como penalidad.
          </li>
          <li>
            El Hotel adquiere el derecho de disponer de la totalidad de las
            habitaciones para su recomercialización en el caso de que el
            contratante cancele noches de habitaciones, aunque se haya ya
            abonado la penalidad.
          </li>
        </ul>
      </p>
      <p>
        En el caso de salidas anticipadas a la fecha de check-out indicada
        originalmente, se aplican las mismas políticas, con un plazo de
        referencia de 48hs.
      </p>
      <p>
      <b>Parking:</b> su tarifa incluye estacionamiento cubierto y/o descubierto, sujeto a disponibilidad, en el mismo predio.
      </p>
      <p>
        <b>No Show:</b> si el huésped no se presenta en el hotel el día indicado y no había cancelado su reserva, se cobrará –tomando en cuenta la seña como parte del pago- el valor de la primera noche de alojamiento o el 50% de la estadía (lo que resulte mayor) y se cancela la reserva. <br />
        El horario de Ingreso es a partir de las  14hs hasta las 24hs, el pasajero debe avisar si llegará más tarde para mantener su reserva. De no tener novedad del pasajero, la reserva podrá cancelarse a las 24hs aplicando No Show.
      </p>
      <p>
     <b> Grupos y Eventos Especiales:</b> estas condiciones no necesariamente aplican en caso de cotizaciones para grupos, eventos o fechas especiales. Consultar previamente con Ventas o Reservas.
      </p>
      <p>
        <b>Reintegro de IVA para Extranjeros (Res. conjunta AFIP 3971 Y MT 566-2016). </b> 
        <ul>
          <li>Se reintegrará el Impuesto al Valor Agregado (IVA) a los pasajeros extranjeros que cumplan con las siguientes condiciones:</li>
          <li>Al momento de la contratación del servicio de alojamiento se deberán identificar la totalidad de los huéspedes que harán uso del servicio. Se requieren los siguiente datos: a) Apellido y nombres. b) Nacionalidad. c) País de residencia. d) N° de pasaporte / Documento de identidad extranjero. - Copia del mismo.</li>
          <li>Asimismo, al momento de la prestación efectiva del servicio de alojamiento, deberán presentar en la recepción el pasaporte extranjero o, en su defecto el documento de identidad extranjero juntamente con el comprobante entregado por la DIRECCIÓN NACIONAL DE MIGRACIONES, de corresponder.</li>
          <li>Para pasajeros que reserven de manera directa con el hotel, sin intermediarios, el pago del servicio debe instrumentarse mediante tarjeta de crédito o débito internacional emitida en el exterior o transferencia de divisas desde el exterior.</li>
        </ul>
      </p>
    </div>
  );
}
