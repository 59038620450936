import React from "react";
import NavReact from "../components/NavReact";
import VisitantesExcel from "../components/VisitantesExcel";
export default function Visitantes() {
    return (
        <div>
            <NavReact />
            <VisitantesExcel />
        </div>
    );
}
