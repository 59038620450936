import React from "react";
import { Card } from "react-bootstrap";
import sinfoto from "../assets/img/sinfoto.jpg";

export default function ProductoCard({ data }) {
  const { imagenCuadrada, nombre, precio } = data;

  return (
    <div className="mt-2 mx-2 mx-lg-3 mt-lg-3 productos-cards" >
      <Card className="border-0 d-lg-flex d-none">
        <Card.Img variant="top" src={imagenCuadrada===""?sinfoto:imagenCuadrada} alt="Producto hotel tafi" />
        <Card.Body className="text-center ">
          <Card.Title className="peso-medium tamaño-caja d-flex flex-column justify-content-center"> {nombre} </Card.Title>
          <Card.Text className="peso-medium">{precio}</Card.Text>
        </Card.Body>
      </Card>
      <Card className="border-0 d-flex d-lg-none">
        <Card.Img className="" variant="top" src={imagenCuadrada===""?sinfoto:imagenCuadrada} alt="Producto hotel tafi" />
        <Card.Body className="text-center ">
          <Card.Title className="peso-medium tamaño-chico tamaño-caja d-flex flex-column justify-content-center"> {nombre} </Card.Title>
          <Card.Text className="peso-medium tamaño-chico">{precio}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}