import { Image } from "react-bootstrap";
import logofooter from "../assets/img/logofooter.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className=" text-white color-gris-claro-fondo mt-auto ">
      <div className="px-4 pt-4 container">
        <div className="row">
          {/* Column1 */}
          <div className="col-12 col-lg-4 text-md-start d-flex justify-content-lg-start justify-content-center mx-auto mt-3 mt-md-0 ">
            <ul className="list-unstyled">
              <li className="tamaño-medio text-center ">
                <Image
                  className="w-100"
                  src={logofooter}
                  alt="logoHotel"
                  fluid
                />
              </li>
            </ul>
          </div>
          {/* Column2 */}
          <div className="col-12 col-lg-4 text-md-start d-flex justify-content-center mx-auto mt-3 ">
            <ul className="list-unstyled">
              <li>
                <p className="tamaño-chico text-center  mt-2 mb-0">
                  info@hoteltafi.com | +549 381569-7400 <br />
                  Av. Belgrano 177 - Tafí del Valle <br />
                  Tucumán - Argentina
                </p>
              </li>
            </ul>
          </div>
          {/* Column3 */}
          <div className="col-12 col-lg-4 tamaño-chico  text-center text-md-start d-flex justify-content-center justify-content-lg-end mb-1 mt-2 p-1 ">
            <ul className="list-unstyled ">
              <li className=" mb-3 mt-2">
                <Link className="sin-sub text-white" to="/PoliticaGenerales">
                  Políticas generales del hotel
                </Link>
              </li>
              {/* <li className="mb-3">
                <Link className="sin-sub text-white" to="/ProtocoloLimpieza">
                  Protocolo de limpieza
                </Link>
              </li>
              <li>
                <Link className="sin-sub text-white" to="/Seguro">
                  {" "}
                  Seguro de cancelación y asistencia
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="p-2 color-gris-fondo ">
        <div className="text-center tamaño-chico  text-md-start my-2 d-flex justify-content-center  ">
          <a
            className="sin-sub text-white"
            href="https://www.dkzstudio.com/"
            target="_blank"
            rel="noreferrer"
          >
            <span>
              Produced by DKZ Studio &copy; {new Date().getFullYear()}
            </span>
            <span> Todos los derechos reservados</span>
          </a>
        </div>
      </div>
    </div>
  );
}
