import React from 'react'
import NavReact from '../components/NavReact'
import ProtocoloDeLimpieza from '../components/ProtocoloDeLimpieza'

export default function ProtocoloLimpieza() {
  return (
    <div>
      <NavReact />
      <ProtocoloDeLimpieza />
    </div>
  )
}
