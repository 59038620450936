import React from "react";
import NavReact from "../components/NavReact";
import PoliticaContenido from "../components/PoliticaContenido";


export default function PoliticaGenerales() {
  return (
    <div className="container">
      <NavReact />
     <PoliticaContenido />
    </div>
  );
}
