import React from 'react'
import NavReact from '../components/NavReact'
import SeguroCancelacionAsistencia from '../components/SeguroCancelacionAsistencia'

export default function Seguro() {
  return (
    <div>
      <NavReact />
      <SeguroCancelacionAsistencia />
    </div>
  )
}
