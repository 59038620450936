import BannerTipo2 from "../components/BannerTipo2";
import ComodidadesConfort from "../components/ComodidadesConfort";
import NavReact from "../components/NavReact";

export default function Confort() {
  return (
    <div>
      <BannerTipo2 />
      <NavReact />
      <div className="container">
        <ComodidadesConfort />
      </div>
    </div>
  );
}
