import React from "react";
import { Image } from "react-bootstrap";
import mapa from "../assets/img/mapa.png";

export default function DescubreMapa() {
  return (
    <div className="container d-flex flex-column justify-content-center text-center">
      <p className="tamaño-mas-grande peso-italic color-negro pt-5 d-none d-lg-block">
        ¡Te esperamos!
      </p>
      <p className="tamaño-grande peso-italic color-negro pt-5 d-lg-none d-block">
        ¡Te esperamos!
      </p>
      <p className="px-5 d-none d-lg-block">
        Av. Belgrano 177 - Tafí del Valle - Tucumán
      </p>
      <p className="px-5 d-lg-none d-block tamaño-chico">
        Av. Belgrano 177 - Tafí del Valle - Tucumán
      </p>

      <a
        href="https://www.google.com.ar/maps/place/Av.+Belgrano+177,+T4135+Tafí+del+Valle,+Tucumán/@-26.8542763,-65.7130475,17z/data=!3m1!4b1!4m5!3m4!1s0x942222872c12c85b:0x5fcd1b400d701bdc!8m2!3d-26.8542811!4d-65.7108535"
        target="_blank"
        rel="noreferrer"
      >
        <Image className=" " src={mapa} alt="Mapa" fluid />
      </a>
    </div>
  );
}
