import React from "react";
import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import bannertipo1 from "../assets/img/bannertipo1.jpg";
import bannercel1 from "../assets/img/bannercel1.jpg";
import bannertipo1a from "../assets/img/bannertipo1a.png";
import bannertipo1acel from "../assets/img/bannertipo1acel.jpg";

export default function BannerTipo1() {
  return (
    <Carousel fade>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image
          className="d-none d-sm-block"
          src={bannertipo1}
          alt="banner"
          fluid
        />
        <Image
          className="d-sm-none d-block"
          src={bannercel1}
          alt="banner"
          fluid
        />
      </Carousel.Item>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image
          className="d-none d-sm-block"
          src={bannertipo1a}
          alt="banner"
          fluid
        />
        <Image
          className="d-sm-none d-block"
          src={bannertipo1acel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
    </Carousel>
  );
}
