import React from "react";
import BannerTipo1 from "../components/BannerTipo1";
import ComodidadesEstandar from "../components/ComodidadesEstandar";
import NavReact from "../components/NavReact";

export default function Estandar() {
  return (
    <div>
      <BannerTipo1 />
      <NavReact />
      <div className="container">
        <ComodidadesEstandar />
      </div>
    </div>
  );
}
