import axios from "axios";
import { useEffect, useState } from "react";
import Papa from "papaparse";
import OfertaCard from "./OfertaCard";
import { Button, Spinner } from "react-bootstrap";

export default function OfertasExcel() {
    const [ofertas, setOfertas] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const getOfertas = async () => {
            const response = await axios.get(
                "https://docs.google.com/spreadsheets/d/e/2PACX-1vT6uYs8LSFy65QbaODsfV6iy6vYPiy2qD8gn67FmxZJwDFig85XgO4RSc9QHKanw5jiwZNsw84fx8vZ/pub?gid=1184987714&single=true&output=csv"
            );

            const articulos = Papa.parse(response.data, { header: true });

            setOfertas(articulos.data);
            setLoading(false);
        };
        getOfertas();
    }, []);
    return (
        <div>
            <p className="peso-italic color-negro tamaño-mas-grande interlineado2 text-center my-5 d-none d-lg-block">
                Ofertas
            </p>
            <p className="peso-italic color-negro tamaño-grande interlineado2 text-center my-4 d-lg-none d-block">
                Ofertas
            </p>

            <div className="pb-5">
                <p className="d-none d-lg-block text-center px-5 mb-5">
                    Reservando aquí, podrás gozar de un descuento del 20% sobre
                    la tarifa de mostrador
                </p>
                <p className="tamaño-chico d-lg-none d-block text-center px-5 mb-5">
                    Reservando aquí, podrás gozar de un descuento del 20% sobre
                    la tarifa de mostrador
                </p>
                <div className="text-center">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wubook.net/neb/bk?f=today&n=1&ep=710b23c4&board=bb&lang=es&jr=%5B%7B%22occupancy%22%3A%7B%22adults%22%3A2%2C%22children%22%3A0%2C%22teens%22%3A0%2C%22babies%22%3A0%7D%7D%5D"
                    >
                        <Button className="color-negro-fondo boton-reserva">
                            <p className="text-white mb-0 peso-medium tamaño-medio mx-2">
                                ¡QUIERO RESERVAR!
                            </p>
                        </Button>
                    </a>
                </div>
            </div>
            {loading ? (
                <div className="my-5 text-white d-flex justify-content-center my-5 p-5">
                    <Spinner
                        className="fs-1"
                        animation="border"
                        role="status"
                        variant="dark"
                    ></Spinner>
                </div>
            ) : (
                <div className="container d-flex flex-wrap justify-content-center row mx-auto">
                    {ofertas.map((oferta, id) => (
                        <OfertaCard key={id} data={oferta} />
                    ))}
                </div>
            )}
        </div>
    );
}
