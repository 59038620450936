import React from "react";
import { Button } from "react-bootstrap";

export default function Recorrido() {
    return (
        <div>
            <div className="container text-center my-5 d-none d-lg-block">
                <p className="peso-italic tamaño-mas-grande color-negro">
                    Un lugar para reconectar
                </p>
                <a href="https://hoteltafi.com/recorrido360/" target="_blank" rel="noreferrer">
                    <Button
                        className="peso-medium color-blanco-fondo color-gris tamaño-medio"
                        variant="outline-secondary"
                    >
                        RECORRIDO 360°
                    </Button>
                </a>
            </div>
            <div className="container text-center my-5 d-lg-none d-block">
                <p className="peso-italic tamaño-grande color-negro">
                    Un lugar para reconectar
                </p>
                <a href="https://hoteltafi.com/recorrido360/" target="_blank" rel="noreferrer">
                    <Button
                        className="peso-medium color-blanco-fondo color-gris"
                        variant="outline-secondary"
                    >
                        RECORRIDO 360°
                    </Button>
                </a>
            </div>
        </div>
    );
}
