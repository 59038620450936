import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import slider1 from "../assets/img/homecarousel4.png";
import slider1cel from "../assets/img/homecarouselcel4.png";

export default function BannerSabores() {
  return (
    <Carousel fade indicators={false} controls={false}>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image className="d-none d-sm-block" src={slider1} alt="banner" fluid />
        <Image
          className="d-sm-none d-block"
          src={slider1cel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
    </Carousel>
  );
}
