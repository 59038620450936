import React from "react";
import NavReact from "../components/NavReact";
import Productos from "../components/Productos";

export default function Menu() {
    return (
        <div>
            <NavReact />
            <Productos />
        </div>
    );
}
