import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import cabalgata from "../assets/img/cabalgata.png";
import deportesAcuaticos from "../assets/img/deportesacuaticos.png";
import museos from "../assets/img/museos.png";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function DescubreFotos2() {
  const { width } = useWindowDimensions();

  return (
    <div>
      <div className="d-none d-lg-flex justify-content-center mt-5">
        <div className="">
          <Image
            className=" w-100 px-3"
            src={cabalgata}
            alt="cabalgatas"
            fluid
          />
        </div>
        <div className="">
          <Image
            className=" w-100 px-3"
            src={deportesAcuaticos}
            alt="deportes"
            fluid
          />
        </div>
        <div className="">
          <Image className=" w-100 px-3" src={museos} alt="museos" fluid />
        </div>
      </div>
      <div className="d-lg-none d-block">
        <Swiper
          slidesPerView={width >= 425 ? width / 315 : width / 315}
          spaceBetween={30}
          className="margen-comentario mt-5"
        >
          <SwiperSlide className="descubre-fotos-ancho4">
            <Image
              className="descubre-fotos-alto"
              src={cabalgata}
              alt="Imagen4"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="descubre-fotos-ancho5">
            <Image
              className="descubre-fotos-alto"
              src={deportesAcuaticos}
              alt="Imagen5"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="descubre-fotos-ancho6">
            <Image
              className="descubre-fotos-alto"
              src={museos}
              alt="Imagen6"
              fluid
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
