import React from 'react'

export default function SeguroCancelacionAsistencia() {
  return (
      <div className="container">
           <h1 className="text-center my-5"> Seguro de Cancelación y Asistencia</h1>
      <p>
        Lorem ipsum dolor sit amet. Eum voluptatum repudiandae qui distinctio
        illo et excepturi sunt eos similique harum cum ratione cumque eum
        quaerat suscipit quo reprehenderit quam? Et dolores consequatur qui
        consequatur maiores eum repellendus distinctio quo voluptas quia. Et
        adipisci delectus sed reiciendis quae ad eaque iste aut natus illo vel
        velit earum.{" "}
      </p>
      <p>
        Non itaque sed ratione vero aut quae quisquam a voluptatibus totam. Est
        maiores deserunt non illo dolor eum velit labore ut adipisci omnis sit
        fugiat consequatur.{" "}
      </p>
      <p>
        Ut voluptatem nesciunt aut esse possimus est maiores perferendis. Eum
        quibusdam aperiam a unde illum et itaque sapiente in pariatur rerum. Aut
        exercitationem officiis eum neque cupiditate et repellat nulla. Nam
        omnis doloremque qui quasi galisum et molestiae sequi et minus
        consequatur qui dolores excepturi.{" "}
      </p>
      <p>
        Ut natus saepe et nobis praesentium id laboriosam rerum id velit optio
        At internos ipsa ea doloribus accusamus. Non amet sapiente doloribus
        magnam ex eligendi voluptas. Est velit sapiente vel adipisci aliquam et
        explicabo consequatur qui consequatur dignissimos et vitae nihil aut
        maxime earum! Quo sint expedita quo molestiae natus et Quis dolorem non
        pariatur similique est quas quia sed corporis quisquam!{" "}
      </p>
      <p>
        Et natus officiis vel quis velit ut optio omnis est quia molestias. Ab
        voluptatibus odit sed optio iusto aut autem provident. Sit facilis
        consequuntur in suscipit qui reiciendis autem id obcaecati illum et
        molestiae nihil cum doloremque sunt. In ducimus deserunt hic deserunt
        minima in illum repellat sed commodi reprehenderit ut voluptatum iure.{" "}
      </p>
      <p>
        Cum eaque minus quo illo pariatur ut perferendis eius At cupiditate
        repudiandae. Id nisi omnis est minima dicta qui distinctio voluptatem ut
        dolorem placeat maiores quod. Vel cupiditate modi aut natus praesentium
        qui sunt inventore et facilis consequuntur ut autem voluptatum.{" "}
      </p>
    </div>
  )
}
