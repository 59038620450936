import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import delicias from "../assets/img/delicias.png";
import deliciasCel from "../assets/img/deliciascel.png";

import juegos from "../assets/img/salasdejuego.png";
import aire from "../assets/img/airelibre.png";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function DescubreFotos1() {
  const { width } = useWindowDimensions();

  return (
    <div>
      <div className="d-none d-xl-flex justify-content-center">
        <div className="">
          <Image className=" w-100 px-3" src={delicias} alt="delicias" fluid />
        </div>
        <div className="">
          <Image
            className=" w-100 px-3"
            src={juegos}
            alt="sala de juegos"
            fluid
          />
        </div>
        <div className="">
          <Image className=" w-100 px-3" src={aire} alt="aire libre" fluid />
        </div>
      </div>
      <div className="d-xl-none d-block">
        <Swiper
          slidesPerView={width >= 770 ? width / 800 : width / 350}
          spaceBetween={30}
          className="margen-comentario my-5"
        >
          <SwiperSlide className="descubre-fotos-ancho1">
            <Image
              className="descubre-fotos-alto "
              src={width >= 770 ? delicias : deliciasCel}
              alt="delicias"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="descubre-fotos-ancho2">
            <Image
              className="descubre-fotos-alto"
              src={juegos}
              alt="Imagen2"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="descubre-fotos-ancho3">
            <Image
              className="descubre-fotos-alto"
              src={aire}
              alt="Imagen3"
              fluid
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
