import React from "react";

export default function SaboresContenido() {
    return (
        <div>
            <div className="text-center my-5">
                <p className="tamaño-mas-grande peso-italic d-none d-lg-block interlineado2">
                    {" "}
                    Cuando el amor es el condimento, <br />
                    el éxito es el resultado
                </p>
                <p className="tamaño-grande peso-italic d-lg-none d-block">
                    {" "}
                    Cuando el amor es el condimento, <br />
                    el éxito es el resultado
                </p>

                <p className="d-none d-lg-block mt-5 px-5">
                    {" "}
                    Trabajamos con amor e ingredientes frescos en nuestra
                    propuesta gastronómica. Contamos con una amplia carta de
                    platos tradicionales y gourmet. <br/>Entre los platos más
                    destacados y típicos, se distinguen las empanadas tucumanas,
                    de carne cortada a cuchillo, el locro, los tamales y la
                    humita en chala o al plato.
                </p>
                <p className="tamaño-chico d-lg-none d-block">
                    Trabajamos con amor e ingredientes frescos en nuestra
                    propuesta gastronómica. Contamos con una amplia carta de
                    platos tradicionales y gourmet.<br/>Entre los platos más
                    destacados y típicos, se distinguen las empanadas tucumanas,
                    de carne cortada a cuchillo, el locro, los tamales y la
                    humita en chala o al plato.
                </p>
            </div>
        </div>
    );
}
