import React from "react";
import BannerSabores from "../components/BannerSabores";
import CardReserva from "../components/CardReserva";
import EnterateSabores from "../components/EnterateSabores";
import NavReact from "../components/NavReact";
import SaboresContenido from "../components/SaboresContenido";
import SaboreSlider from "../components/SaboreSlider";

export default function Sabores() {
    return (
        <div>
            <BannerSabores />
            <NavReact />
            <div className="container">
                <SaboresContenido />
                <SaboreSlider />
                <EnterateSabores />
            </div>
            <CardReserva />
        </div>
    );
}
