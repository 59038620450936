import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import carousel1 from "../assets/img/homecarousel1.png";
import carousel2 from "../assets/img/homecarousel2.png";
import carousel3 from "../assets/img/homecarousel3.png";
import carousel4 from "../assets/img/homecarousel4.png";

import carousel1cel from "../assets/img/homecarouselcel1.png";
import carousel2cel from "../assets/img/homecarouselcel2.png";
import carousel3cel from "../assets/img/homecarouselcel3.png";
import carousel4cel from "../assets/img/homecarouselcel4.png";

export default function Banner() {
  return (
    <>
      <Carousel fade>
        <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
          <Image
            className="d-none d-sm-block"
            src={carousel1}
            alt="banner"
            fluid
          />
          <Image
            className="d-sm-none d-block"
            src={carousel1cel}
            alt="banner"
            fluid
          />
        </Carousel.Item>
        <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
          <Image
            className="d-none d-sm-block"
            src={carousel2}
            alt="banner"
            fluid
          />
          <Image
            className="d-sm-none d-block"
            src={carousel2cel}
            alt="banner"
            fluid
          />
        </Carousel.Item>
        <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
          <Image
            className="d-none d-sm-block"
            src={carousel3}
            alt="banner"
            fluid
          />
          <Image
            className="d-sm-none d-block"
            src={carousel3cel}
            alt="banner"
            fluid
          />
        </Carousel.Item>
        <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
          <Image
            className="d-none d-sm-block"
            src={carousel4}
            alt="banner"
            fluid
          />
          <Image
            className="d-sm-none d-block"
            src={carousel4cel}
            alt="banner"
            fluid
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
}
