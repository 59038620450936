import React from "react";

export default function Naturaleza() {
    return (
        <div className=" d-flex flex-column justify-content-center text-center container">
            <hr className="color-negro-fondo d-none d-lg-block mx-5" />
            <p className="tamaño-mas-grande peso-italic color-negro pt-5 d-none d-lg-block">
                Naturaleza a pleno
            </p>
            <p className="tamaño-grande peso-italic color-negro pt-lg-5 pt-0 d-lg-none d-block">
                Naturaleza a pleno
            </p>
            <p className="px-5 d-none d-lg-block">
                Desde senderos para recorrer en bici, cabalgatas con expertos o
                una travesía única en Kayak, Tafí del Valle se convierte en un
                destino único para los viajeros que buscan lugares y paisajes
                únicos para recorrer. Desde tres horas o hasta tres días,
                algunas de las propuestas combinan ascensos a los cerros y
                cumbres calchaquíes, cascadas infinitas, sitios arqueológicos y
                poblados cerca del cielo.
            </p>
            <p className="px-5 tamaño-chico d-lg-none d-block">
                Desde senderos para recorrer en bici, cabalgatas con expertos o
                una travesía única en Kayak, Tafí del Valle se convierte en un
                destino único para los viajeros que buscan lugares y paisajes
                únicos para recorrer. Desde tres horas o hasta tres días,
                algunas de las propuestas combinan ascensos a los cerros y
                cumbres calchaquíes, cascadas infinitas, sitios arqueológicos y
                poblados cerca del cielo.
            </p>
            <p className="mt-3 d-none d-lg-block">
                En Hotel Tafí y disfruta de las actividades que tenemos para vos
                y tu familia
            </p>
            <p className="mt-3 tamaño-chico d-lg-none d-block">
                En Hotel Tafí y disfruta de las actividades que tenemos para vos
                y tu familia
            </p>
        </div>
    );
}
