import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import EventoCard from "./EventoCard";
import { Spinner } from "react-bootstrap";
import RedesSociales from "./RedesSociales";

export default function EventosExcel() {
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getEventos = async () => {
      const response = await axios.get(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vT6uYs8LSFy65QbaODsfV6iy6vYPiy2qD8gn67FmxZJwDFig85XgO4RSc9QHKanw5jiwZNsw84fx8vZ/pub?gid=150984213&single=true&output=csv"
      );

      const articulos = Papa.parse(response.data, { header: true });

      setEventos(articulos.data);
      setLoading(false);
    };
    getEventos();
  }, []);
  return (
    <div>
      <p className="peso-italic color-negro tamaño-mas-grande interlineado2 text-center my-5 d-none d-lg-block">
        Eventos
      </p>
      <p className="peso-italic color-negro tamaño-grande interlineado2 text-center my-4 d-lg-none d-block">
        Eventos
      </p>
      <p className="color-negro text-center my-5 container d-none d-lg-block">
        En Hotel Tafí te invitamos a que festejes tu cumpleaños, aniversario o
        casamiento de manera diferente: organizamos una experiencia única que
        recordarás por siempre. Podemos gestionar paseos, caminatas, cabalgatas,
        visitas a lugares de interés, actividades en el lago, o un gran asado en
        nuestro quincho, una cena romántica o divertida con show. Para un evento
        importante, como una fiesta de quince años o un casamiento, reservamos
        todo para vos y tus invitados y nos encargamos de todos los detalles:
        carpas, islas, comedor, las habitaciones, el estacionamiento y el
        servicio gastronómico de nuestro querido Guillermo Fares (Tarantino, San
        Miguel de Tucumán) para que disfrutes de un evento único e inolvidable.
      </p>

      <p className="color-negro tamaño-chico text-center my-4 container d-lg-none d-block">
        En Hotel Tafí te invitamos a que festejes tu cumpleaños, aniversario o
        casamiento de manera diferente: organizamos una experiencia única que
        recordarás por siempre. Podemos gestionar paseos, caminatas, cabalgatas,
        visitas a lugares de interés, actividades en el lago, o un gran asado en
        nuestro quincho, una cena romántica o divertida con show. Para un evento
        importante, como una fiesta de quince años o un casamiento, reservamos
        todo para vos y tus invitados y nos encargamos de todos los detalles:
        carpas, islas, comedor, las habitaciones, el estacionamiento y el
        servicio gastronómico de nuestro querido Guillermo Fares (Tarantino, San
        Miguel de Tucumán) para que disfrutes de un evento único e inolvidable.
      </p>
      {loading ? (
        <div className="my-5 text-white  d-flex justify-content-center my-5 p-5">
          <Spinner
            className="fs-1"
            animation="border"
            role="status"
            variant="dark"
          ></Spinner>
        </div>
      ) : (
        <div className="container d-flex flex-wrap justify-content-center row mx-auto">
          {eventos.map((evento, id) => (
            <EventoCard key={id} data={evento} />
          ))}
        </div>
      )}
      <div className="text-center">
        <RedesSociales />
      </div>
    </div>
  );
}
