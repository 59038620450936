import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import superior from "../assets/img/superior1.jpg";
import superiorCel from "../assets/img/superior1cel.jpg";
import superior2 from "../assets/img/superior2.jpg";
import superior2cel from "../assets/img/superior2cel.jpg";

export default function BannerTipo3() {
  return (
    <Carousel fade>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image
          className="d-none d-sm-block"
          src={superior}
          alt="banner"
          fluid
        />
        <Image
          className="d-sm-none d-block"
          src={superiorCel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image
          className="d-none d-sm-block"
          src={superior2}
          alt="banner"
          fluid
        />
        <Image
          className="d-sm-none d-block"
          src={superior2cel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
    </Carousel>
  );
}
