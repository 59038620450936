import React from "react";
import BannerDescubre from "../components/BannerDescubre";
import CardReserva from "../components/CardReserva";
import DescubreFotos1 from "../components/DescubreFotos1";
import DescubreFotos2 from "../components/DescubreFotos2";
import DescubreMapa from "../components/DescubreMapa";
import Naturaleza from "../components/Naturaleza";
import NavReact from "../components/NavReact";
import ViviTafi from "../components/ViviTafi";

export default function Descubre() {
    return (
        <div className="">
            <BannerDescubre />
            <NavReact />
            <ViviTafi />
            <DescubreFotos1 />
            <Naturaleza />
            <DescubreFotos2 />
            <DescubreMapa />
            <CardReserva />
        </div>
    );
}
