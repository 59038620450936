import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import RedesSociales from "./RedesSociales";
export default function VentajasReserva() {
    return (
        <div>
            <div className="text-center my-5">
                <p className="tamaño-mas-grande peso-italic d-none d-lg-block my-5">
                    {" "}
                    Ventajas exclusivas al reservar en nuestra web
                </p>
                <p className="tamaño-grande peso-italic d-lg-none d-block my-4">
                    {" "}
                    Ventajas exclusivas al reservar en nuestra web
                </p>

                <p className="d-none d-lg-block ">
                    {" "}
                    El sistema de reservas de Hotel Tafi es cerrado y la
                    información proporcionada es confidencial. Los datos
                    personales quedan guardados solo a fin de realizar el check
                    in y check out en el Hotel de forma rápida y ágil. Nunca te
                    pediremos datos de accesos a tu cuenta bancaria ni claves,
                    para dejar firme la reserva, puedes hacerlo realizando un
                    pago desde Mercado Pago, o bien, mediante comunicación
                    directa con nuestros recepcionistas para que te informen de
                    distintos medios de pago.
                </p>
                <p className="tamaño-chico  d-lg-none d-block ">
                    El sistema de reservas de Hotel Tafi es cerrado y la
                    información proporcionada es confidencial. Los datos
                    personales quedan guardados solo a fin de realizar el check
                    in y check out en el Hotel de forma rápida y ágil. Nunca te
                    pediremos datos de accesos a tu cuenta bancaria ni claves,
                    para dejar firme la reserva, puedes hacerlo realizando un
                    pago desde Mercado Pago, o bien, mediante comunicación
                    directa con nuestros recepcionistas para que te informen de
                    distintos medios de pago.
                </p>
            </div>
            {/* <div className="d-flex flex-wrap justify-content-around mt-5">
        <div>
          <Card className="border-0" style={{ width: "18rem" }}>
            <div className="d-flex justify-content-center">
              <Card.Img
                className="w-25 "
                src="https://icongr.am/clarity/home.svg?size=81&color=currentColor"
              />
            </div>
            <Card.Body>
              <Card.Title className="text-center">icono</Card.Title>
              <Card.Text className="tamaño-chico text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card className="border-0" style={{ width: "18rem" }}>
            <div className="d-flex justify-content-center">
              <Card.Img
                className="w-25 "
                src="https://icongr.am/clarity/home.svg?size=81&color=currentColor"
              />
            </div>
            <Card.Body>
              <Card.Title className="text-center">icono</Card.Title>
              <Card.Text className="tamaño-chico text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card className="border-0" style={{ width: "18rem" }}>
            <div className="d-flex justify-content-center">
              <Card.Img
                className="w-25 "
                src="https://icongr.am/clarity/home.svg?size=81&color=currentColor"
              />
            </div>
            <Card.Body>
              <Card.Title className="text-center">icono</Card.Title>
              <Card.Text className="tamaño-chico text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div> */}
            <div className="text-center">
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wubook.net/neb/bk?f=today&n=1&ep=710b23c4&board=bb&lang=es&jr=%5B%7B%22occupancy%22%3A%7B%22adults%22%3A2%2C%22children%22%3A0%2C%22teens%22%3A0%2C%22babies%22%3A0%7D%7D%5D"
                >
                    <Button className="color-negro-fondo boton-reserva">
                        <p className="text-white mb-0 peso-medium tamaño-medio mx-2">
                            ¡QUIERO RESERVAR!
                        </p>
                    </Button>
                </a>
            </div>
            <div className="text-center">
                <RedesSociales />
            </div>
            {/*      
      <div className="my-5 text-center">
        <p className=" tamaño-mas-grande d-none d-lg-block peso-italic "> ¡Aprovecha!</p>
        <p className=" tamaño-grande peso-italic text-center d-lg-none d-block"> ¡Aprovecha!</p>
      </div> */}
        </div>
    );
}
