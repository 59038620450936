import "./App.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Descubre from "./pages/Descubre";
import Habitaciones from "./pages/Habitaciones";
import Sabores from "./pages/Sabores";
import Visitantes from "./pages/Visitantes";
import Ofertas from "./pages/Ofertas";
import Eventos from "./pages/Eventos";
import Reserva from "./pages/Reserva";
import ProtocoloLimpieza from "./pages/ProtocoloLimpieza";
import Seguro from "./pages/Seguro";
import Superior from "./pages/Superior";
import Estandar from "./pages/Estandar";
import Confort from "./pages/Confort";
import Menu from "./pages/Menu";
import ReservaBoton from "./components/ReservaBoton";
import PoliticaGenerales from "./pages/PoliticaGenerales";

function App() {
    return (
        <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
            <HashRouter>
                <ScrollToTop />
                <ReservaBoton />

                <Routes >
                    <Route path="/" element={<Home />} />
                    <Route path="/Descubre" element={<Descubre />} />
                    <Route path="/Habitaciones" element={<Habitaciones />} />
                    <Route
                        path="/Habitaciones/Estandar"
                        element={<Estandar />}
                    />
                    <Route path="/Habitaciones/Confort" element={<Confort />} />
                    <Route
                        path="/Habitaciones/Superior"
                        element={<Superior />}
                    />
                    <Route path="/Sabores" element={<Sabores />} />
                    <Route path="/Visitantes" element={<Visitantes />} />
                    <Route path="/Menu" element={<Menu />} />
                    <Route path="/Ofertas" element={<Ofertas />} />
                    <Route path="/Eventos" element={<Eventos />} />
                    <Route path="/Reserva" element={<Reserva />} />
                    <Route
                        path="/PoliticaGenerales"
                        element={<PoliticaGenerales />}
                    />
                    <Route
                        path="/ProtocoloLimpieza"
                        element={<ProtocoloLimpieza />}
                    />
                    <Route path="/Seguro" element={<Seguro />} />
                </Routes>
                <Footer />
            </HashRouter>
        </div>
    );
}

export default App;
