import React from "react";
import NavReact from "../components/NavReact";
import OfertasExcel from "../components/OfertasExcel";

export default function Ofertas() {
    return (
        <div>
            <NavReact />
            <OfertasExcel />
        </div>
    );
}
