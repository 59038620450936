import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import VisitanteCard from "./VisitanteCard";
import { Image, Spinner } from "react-bootstrap";
import facebook from "../assets/img/face.svg";
import instagram from "../assets/img/ig.svg";
import wapp from "../assets/img/wapp.svg";

export default function VisitantesExcel() {
  const [visitantes, setVisitantes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getVisitantes = async () => {
      const response = await axios.get(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vT6uYs8LSFy65QbaODsfV6iy6vYPiy2qD8gn67FmxZJwDFig85XgO4RSc9QHKanw5jiwZNsw84fx8vZ/pub?gid=1000794116&single=true&output=csv"
      );

      const articulos = Papa.parse(response.data, { header: true });

      setVisitantes(articulos.data);
      setLoading(false);
    };
    getVisitantes();
  }, []);
  return (
    <div>
      <p className="peso-italic color-negro tamaño-mas-grande interlineado2 text-center my-5 d-none d-lg-block">
        Visitantes
      </p>
      <p className="peso-italic color-negro tamaño-grande interlineado2 text-center my-4 d-lg-none d-block">
        Visitantes
      </p>
      <p className="color-negro interlineado2 text-center mt-5 d-none d-lg-block">
        ¿Ya nos visitaste?{" "}
        <a
          className=""
          href="https://www.google.com/travel/hotels/Hotel%20Tafi%2C%20Av.%20Belgrano%20177/entity/CgoIzc__hbfCiMhDEAE/reviews?q=google%20hotel%20tafi&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4515404%2C4597339%2C4703207%2C4718358%2C4722900%2C4723331%2C4741665%2C4757164%2C4758493%2C4762561%2C4786958%2C4790928%2C4794648%2C4806994%2C4810326%2C4814050%2C4816256%2C4819463%2C4819467&hl=es-419&gl=ar&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAJGBQSBwjmDxAJGBUYATICEAAqCQoFOgNBUlMaAA&rp=EM3P_4W3wojIQxCP7trg0o6MxecBEMiXm9qC7tvm1wEQoPja_t_zlPxEOAFAAEgCogEcSG90ZWwgVGFmaSwgQXYuIEJlbGdyYW5vIDE3N8ABAw&ap=aAE&ictx=1&utm_campaign=sharing&utm_medium=link&utm_source=htls&ved=0CAAQ5JsGahcKEwjgqcKXzPL5AhUAAAAAHQAAAAAQBw"
          target="_blank"
          rel="noreferrer"
        >
          Dejanos tu opinión
        </a>
      </p>
      <p className="color-negro interlineado2 text-center mb-5 d-none d-lg-block mx-5 px-5">
        ¿Queres hacernos una consulta o un comentario sobre nuestro servicio?
        <div className="d-none d-lg-block text-center my-5">
          <p>
            <a
              className="sin-sub"
              href="https://walink.co/5a41ff"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={wapp} fluid alt="icono" />
            </a>
            <a
              className="sin-sub "
              href="https://www.facebook.com/HotelTafi/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={facebook} fluid alt="icono" />
            </a>
            <a
              className="sin-sub"
              href="https://www.instagram.com/hoteltafi/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={instagram} fluid alt="icono" />
            </a>
          </p>
        </div>
        <div className="d-lg-none d-block my-2 text-center">
          <a
            className="sin-sub"
            href="https://walink.co/5a41ff"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="iconos-reservar" src={wapp} fluid alt="icono" />
          </a>
          <a
            className="sin-sub"
            href="https://www.facebook.com/HotelTafi/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              className="iconos-reservar"
              src={facebook}
              fluid
              alt="icono"
            />{" "}
          </a>
          <a
            className="sin-sub"
            href="https://www.instagram.com/hoteltafi/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              className="iconos-reservar"
              src={instagram}
              fluid
              alt="icono"
            />
          </a>
        </div>
      </p>
      <p className="color-negro tamaño-chico interlineado2 text-center my-4 d-lg-none d-block">
        ¿Ya nos visitaste?{" "}
        <a
          className=""
          href="https://www.google.com/travel/hotels/Hotel%20Tafi%2C%20Av.%20Belgrano%20177/entity/CgoIzc__hbfCiMhDEAE/reviews?q=google%20hotel%20tafi&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4515404%2C4597339%2C4703207%2C4718358%2C4722900%2C4723331%2C4741665%2C4757164%2C4758493%2C4762561%2C4786958%2C4790928%2C4794648%2C4806994%2C4810326%2C4814050%2C4816256%2C4819463%2C4819467&hl=es-419&gl=ar&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAJGBQSBwjmDxAJGBUYATICEAAqCQoFOgNBUlMaAA&rp=EM3P_4W3wojIQxCP7trg0o6MxecBEMiXm9qC7tvm1wEQoPja_t_zlPxEOAFAAEgCogEcSG90ZWwgVGFmaSwgQXYuIEJlbGdyYW5vIDE3N8ABAw&ap=aAE&ictx=1&utm_campaign=sharing&utm_medium=link&utm_source=htls&ved=0CAAQ5JsGahcKEwjgqcKXzPL5AhUAAAAAHQAAAAAQBw"
          target="_blank"
          rel="noreferrer"
        >
          Dejanos tu opinión
        </a>
      </p>
      <p className="color-negro tamaño-chico text-center mt-4 d-lg-none d-block mx-5 px-5">
        ¿Queres hacernos una consulta o un comentario sobre nuestro servicio?
        <div className="d-none d-lg-block text-center my-5">
          <p>
            <a
              className="sin-sub"
              href="https://walink.co/5a41ff"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={wapp} fluid alt="icono" />
            </a>
            <a
              className="sin-sub "
              href="https://www.facebook.com/HotelTafi/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={facebook} fluid alt="icono" />
            </a>
            <a
              className="sin-sub"
              href="https://www.instagram.com/hoteltafi/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={instagram} fluid alt="icono" />
            </a>
          </p>
        </div>
        <div className="d-lg-none d-block my-2 text-center">
          <a
            className="sin-sub"
            href="https://walink.co/5a41ff"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="iconos-reservar" src={wapp} fluid alt="icono" />
          </a>
          <a
            className="sin-sub"
            href="https://www.facebook.com/HotelTafi/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              className="iconos-reservar"
              src={facebook}
              fluid
              alt="icono"
            />{" "}
          </a>
          <a
            className="sin-sub"
            href="https://www.instagram.com/hoteltafi/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              className="iconos-reservar"
              src={instagram}
              fluid
              alt="icono"
            />
          </a>
        </div>
      </p>
      {loading ? (
        <div className="my-5 d-flex justify-content-center my-5 p-5">
          <Spinner
            className="fs-1"
            animation="border"
            role="status"
            variant="dark"
          ></Spinner>
        </div>
      ) : (
        <div className="container d-flex flex-wrap justify-content-center row mx-auto">
          {visitantes.map((visitante, id) => (
            <VisitanteCard key={id} data={visitante} />
          ))}
        </div>
      )}
    </div>
  );
}
