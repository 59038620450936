import { Navbar, Container, Nav, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.svg";
export default function NavReact() {
    return (
        <Navbar bg="light" expand="xl" sticky="top">
            <Container>
                <div className="d-flex flex-xl-column">
                    <Navbar.Brand className="mx-auto" as={Link} to="/">
                        <Image src={logo} fluid alt="icono" />
                    </Navbar.Brand>
                    <a
                        href="https://hoteltafi.com/recorrido360/"
                        target="_blank"
                        rel="noreferrer"
                        className="mx-xl-auto my-xl-0 my-auto ms-3"
                    >
                        <Button
                            className="peso-medium color-blanco-fondo color-gris tamaño-mas-chico d-xl-none d-inline-block"
                            variant="outline-secondary"
                        >
                            RECORRIDO 360°
                        </Button>
                    </a>
                    <a
                        href="https://hoteltafi.com/recorrido360/"
                        target="_blank"
                        rel="noreferrer"
                        className=" ms-3 "
                    >
                        <Button
                            className="peso-medium color-blanco-fondo color-gris tamaño-mas-chico d-none d-xl-inline-block position-absolute mt-3"
                            variant="outline-secondary"
                        >
                            RECORRIDO 360°
                        </Button>
                    </a>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium text-nowrap d-none d-xl-block"
                            as={Link}
                            to="/Descubre"
                        >
                            Descubre Tafí
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 tamaño-chico peso-medium text-nowrap d-xl-none d-block"
                            as={Link}
                            to="/Descubre"
                        >
                            Descubre Tafí
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium d-none d-xl-block"
                            as={Link}
                            to="/Habitaciones"
                        >
                            Habitaciones
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 tamaño-chico peso-medium tamaño-chico d-xl-none d-block"
                            as={Link}
                            to="/Habitaciones"
                        >
                            Habitaciones
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium  d-none d-xl-block"
                            as={Link}
                            to="/Sabores"
                        >
                            Sabores
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 peso-medium tamaño-chico d-xl-none d-block"
                            as={Link}
                            to="/Sabores"
                        >
                            Sabores
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium  d-none d-xl-block"
                            as={Link}
                            to="/Visitantes"
                        >
                            Visitantes
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 peso-medium tamaño-chico d-xl-none d-block"
                            as={Link}
                            to="/Visitantes"
                        >
                            Visitantes
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium  d-none d-xl-block "
                            as={Link}
                            to="/Ofertas"
                        >
                            Ofertas
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 peso-medium tamaño-chico d-xl-none d-block"
                            as={Link}
                            to="/Ofertas"
                        >
                            Ofertas
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium  d-none d-xl-block"
                            as={Link}
                            to="/Eventos"
                        >
                            Eventos
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 peso-medium tamaño-chico d-xl-none d-block"
                            as={Link}
                            to="/Eventos"
                        >
                            Eventos
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto mx-2 tamaño-medio peso-medium  d-none d-xl-block"
                            as={Link}
                            to="/Reserva"
                        >
                            Reservas
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto me-2 tamaño-chico d-xl-none d-block peso-medium"
                            as={Link}
                            to="/Reserva"
                        >
                            Reservas
                        </Nav.Link>
                        {/* <Nav.Link
                            className="ms-auto ms-2 pe-0 tamaño-medio peso-medium  d-none d-xl-block"
                            as={Link}
                            to="/"
                        >
                            <Image
                                className="banderas"
                                src={spain}
                                alt="spain"
                                fluid
                            />
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto pe-0 tamaño-medio peso-medium  d-none d-xl-block"
                            as={Link}
                            to="/"
                        >
                            <Image
                                className="banderas"
                                src={usa}
                                alt="usa"
                                fluid
                            />
                        </Nav.Link>
                        <Nav.Link
                            className="ms-auto tamaño-chico d-xl-none d-block"
                            as={Link}
                            to="/"
                        >
                            <Image
                                className="banderas mx-2"
                                src={spain}
                                alt="spain"
                                fluid
                            />
                            <Image
                                className="banderas me-2"
                                src={usa}
                                alt="usa"
                                fluid
                            />
                        </Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
