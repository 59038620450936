import facebook from "../assets/img/face.svg";
import instagram from "../assets/img/ig.svg";
import wapp from "../assets/img/wapp.svg";
import { Image } from "react-bootstrap";

export default function EnterateSabores() {
  return (
    <div >
      <div className="d-none d-sm-block text-center my-5">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-end">
            <p className="ms-4">
              Entérate de nuestras promos <br />
              siguiéndonos en nuestras redes sociales
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-start">
            <a className="sin-sub ms-4" href="https://walink.co/5a41ff" target="_blank" rel="noreferrer">
              <Image src={wapp} fluid alt="icono" />
            </a>
            <a className="sin-sub " href="https://www.facebook.com/HotelTafi/" target="_blank" rel="noreferrer">
              <Image src={facebook} fluid alt="icono" />
            </a>
            <a className="sin-sub" href="https://www.instagram.com/hoteltafi/" target="_blank" rel="noreferrer">
              <Image src={instagram} fluid alt="icono" />
            </a>
          </div>
        </div>
      </div>
      <div className="d-sm-none d-block my-2 text-center">
        <p className="tamaño-chico"> Entérate de nuestras promos <br />
              siguiéndonos en nuestras redes sociales</p>
        <a className="sin-sub" href="https://walink.co/5a41ff" target="_blank" rel="noreferrer">
          <Image className="iconos-reservar" src={wapp} fluid alt="icono" />
        </a>
        <a className="sin-sub" href="https://www.facebook.com/HotelTafi/" target="_blank" rel="noreferrer">
          <Image className="iconos-reservar" src={facebook} fluid alt="icono" />{" "}
        </a>
        <a className="sin-sub" href="https://www.instagram.com/hoteltafi/" target="_blank" rel="noreferrer">
          <Image
            className="iconos-reservar"
            src={instagram}
            fluid
            alt="icono"
          />
        </a>
      </div>
    </div>
  );
}
