import React from "react";
import { Card, Button } from "react-bootstrap";
import sinfoto from "../assets/img/sinevento.png";

export default function EventoCard({ data }) {
    const { imagen, titulo, subtitulo, link } = data;

    return (
        <div className="mt-3 mx-3 col-sm-5">
            <Card className="border-0 card-ofertas mx-auto ">
                <Card.Img
                    variant="top"
                    src={imagen === "" ? sinfoto : imagen}
                    alt="Evento hotel tafi"
                />
                <Card.Body className="text-center">
                    <Card.Title className="peso-medium tamaño-caja-ofertas">
                        {titulo}
                    </Card.Title>
                    <Card.Text className="peso-medium tamaño-chico">
                        {subtitulo}
                    </Card.Text>
                    <a href={link} target="_blank" rel="noreferrer">
                        <Button
                            className="peso-medium color-blanco-fondo color-gris"
                            variant="outline-secondary"
                        >
                            ¡Contactate!
                        </Button>
                    </a>
                </Card.Body>
            </Card>
        </div>
    );
}
