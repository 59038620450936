import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import sabor1 from "../assets/img/sabor1.png";
import sabor2 from "../assets/img/sabor2.png";
import sabor3 from "../assets/img/sabor3.png";
import sabor4 from "../assets/img/sabor4.png";

export default function SaboreSlider() {
  return (
    <div>
      <div className=" d-flex flex-wrap justify-content-between pb-5">
        <Swiper slidesPerView={3.75} className="">
          <SwiperSlide className="d-flex flex-column mx-2 ">
            <Image
              className="w-100 h-100"
              src={sabor1}
              alt="ImagenDisfruta"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-column  mx-2 ">
            <Image
              className="w-100 h-100"
              src={sabor2}
              alt="ImagenDisfruta"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-column  mx-2">
            <Image
              className="w-100 h-100"
              src={sabor3}
              alt="ImagenDisfruta"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-column mx-2 ">
            <Image
              className="w-100 h-100"
              src={sabor4}
              alt="ImagenDisfruta"
              fluid
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="d-flex justify-content-center">
        <NavLink to="/Menu">
          <Button variant="outline-secondary tamaño-medio peso-medium">
            Menu
          </Button>
        </NavLink>
      </div>
    </div>
  );
}
