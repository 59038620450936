import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import ProductoCard from "./ProductoCard";
import { Spinner } from "react-bootstrap";

export default function Productos() {
    const [productos, setProductos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const getProductos = async () => {
            const response = await axios.get(
                "https://docs.google.com/spreadsheets/d/e/2PACX-1vT6uYs8LSFy65QbaODsfV6iy6vYPiy2qD8gn67FmxZJwDFig85XgO4RSc9QHKanw5jiwZNsw84fx8vZ/pub?gid=0&single=true&output=csv"
            );

            const articulos = Papa.parse(response.data, { header: true });

            setProductos(articulos.data);
            setLoading(false);
        };
        getProductos();
    }, []);

    const Bebidas = productos.filter((prod) => {
        return prod.categoria == "Bebidas sin Alcohol";
    });
    const Brunch = productos.filter((prod) => {
        return prod.categoria == "Brunch";
    });
    const Cafeteria = productos.filter((prod) => {
        return prod.categoria == "Cafetería";
    });
    const Cervezas = productos.filter((prod) => {
        return prod.categoria == "Cervezas";
    });
    const Ensaladas = productos.filter((prod) => {
        return prod.categoria == "Ensaladas";
    });
    const Entradas = productos.filter((prod) => {
        return prod.categoria == "Entradas";
    });
    const Pizzas = productos.filter((prod) => {
        return prod.categoria == "Pizzas";
    });
    const Principal = productos.filter((prod) => {
        return prod.categoria == "Plato principal";
    });
    const Postres = productos.filter((prod) => {
        return prod.categoria == "Postres";
    });
    const Promos = productos.filter((prod) => {
        return prod.categoria == "Promos";
    });
    const Regionales = productos.filter((prod) => {
        return prod.categoria == "Regionales";
    });
    const Salsas = productos.filter((prod) => {
        return prod.categoria == "Salsas";
    });
    const Sanguches = productos.filter((prod) => {
        return prod.categoria == "Sanguches";
    });
    const Tragos = productos.filter((prod) => {
        return prod.categoria == "Tragos";
    });
    const Vinos = productos.filter((prod) => {
        return prod.categoria == "Vinos";
    });

    return (
        <>
            <p className="peso-italic color-negro tamaño-mas-grande interlineado2 text-center my-5 d-none d-lg-block">
                Menu
            </p>
            <p className="peso-italic color-negro tamaño-grande interlineado2 text-center my-4 d-lg-none d-block">
                Menu
            </p>
            {loading ? (
                <div className="my-5 text-white  d-flex justify-content-center my-5 p-5">
                    <Spinner
                        className="fs-1"
                        animation="border"
                        role="status"
                        variant="dark"
                    ></Spinner>
                </div>
            ) : (
                <div className="container">
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Bebidas sin Alcohol
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Bebidas sin Alcohol
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Bebidas.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Brunch
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Brunch
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Brunch.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Cafeteria
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Cafeteria
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Cafeteria.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Cervezas
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Cervezas
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Cervezas.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Ensaladas
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Ensaladas
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Ensaladas.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Entradas
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Entradas
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Entradas.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Pizzas
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Pizzas
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Pizzas.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Plato Principal
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Plato Principal
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Principal.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Postres
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Postres
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Postres.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Promos
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Promos
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Promos.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Regionales
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Regionales
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Regionales.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Salsas
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Salsas
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Salsas.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Sanguches
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Sanguches
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Sanguches.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Tragos
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Tragos
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Tragos.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                    <hr className="mt-5" />
                    <p className="peso-medium color-negro tamaño-grande text-center my-5 d-none d-lg-block">
                        Vinos
                    </p>
                    <p className="peso-medium color-negro text-center my-4 d-lg-none d-block">
                        Vinos
                    </p>{" "}
                    <div className=" d-flex flex-wrap justify-content-center">
                        {Vinos.map((producto, id) => (
                            <ProductoCard key={id} data={producto} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
