import React from "react";
import Banner from "../components/Banner";
import BannerCajas from "../components/BannerCajas";
import CardReserva from "../components/CardReserva";
import CardsVisitantes from "../components/CardsVisitantes";
import ComentariosExcel from "../components/ComentariosExcel";
import NavReact from "../components/NavReact";
import Recorrido from "../components/Recorrido";
import RedesSociales from "../components/RedesSociales";
import ReservaBoton from "../components/ReservaBoton";

export default function Home() {
    return (
        <div>
            <Banner />
            <NavReact />
            <BannerCajas />
            <CardsVisitantes />
            <Recorrido />
            <CardReserva />
            <RedesSociales />
            <ComentariosExcel />
        </div>
    );
}
