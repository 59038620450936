import BannerTipo3 from "../components/BannerTipo3";
import ComodidadesSuperior from "../components/ComodidadesSuperior";
import NavReact from "../components/NavReact";

export default function Superior() {
  return (
    <div>
      <BannerTipo3 />
      <NavReact />
      <div className="container">
        <ComodidadesSuperior />
      </div>
    </div>
  );
}