import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "../assets/img/bannerconfort1.jpg";
import banner1cel from "../assets/img/bannerconfort1cel.jpg";
import banner2 from "../assets/img/bannerconfort2.png";
import banner2cel from "../assets/img/bannerconfort2celu.png";
import banner3 from "../assets/img/bannerconfort3.jpg";
import banner3cel from "../assets/img/bannerconfort3celu.jpg";
export default function BannerTipo2() {
  return (
    <Carousel fade>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image className="d-none d-sm-block" src={banner1} alt="banner" fluid />
        <Image
          className="d-sm-none d-block"
          src={banner1cel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image className="d-none d-sm-block" src={banner2} alt="banner" fluid />
        <Image
          className="d-sm-none d-block"
          src={banner2cel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image className="d-none d-sm-block" src={banner3} alt="banner" fluid />
        <Image
          className="d-sm-none d-block"
          src={banner3cel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
    </Carousel>
  );
}
