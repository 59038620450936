import facebook from "../assets/img/face.svg";
import instagram from "../assets/img/ig.svg";
import wapp from "../assets/img/wapp.svg";
import { Image } from "react-bootstrap";

export default function RedesSociales() {
  return (
    <div className="container">
      <div className="d-none d-lg-block text-center my-5">
        <p >
        <a className="sin-sub  " href="https://walink.co/5a41ff" target="_blank" rel="noreferrer">
              <Image src={wapp} fluid alt="icono" />
            </a>
            <a className="sin-sub " href="https://www.facebook.com/HotelTafi/" target="_blank" rel="noreferrer">
              <Image src={facebook} fluid alt="icono" />
            </a>
            <a className="sin-sub" href="https://www.instagram.com/hoteltafi/" target="_blank" rel="noreferrer">
              <Image src={instagram} fluid alt="icono" />
            </a>
        </p>
      </div>
      <div className="d-lg-none d-block my-2 text-center">
        <p className="tamaño-chico">
          También puedes reservar por WhatsApps
        </p>
        <a className="sin-sub " href="https://walink.co/5a41ff" target="_blank" rel="noreferrer">
              <Image src={wapp} fluid alt="icono" />
            </a>
            <a className="sin-sub  " href="https://www.facebook.com/HotelTafi/" target="_blank" rel="noreferrer">
              <Image src={facebook} fluid alt="icono" />
            </a>
            <a className="sin-sub " href="https://www.instagram.com/hoteltafi/" target="_blank" rel="noreferrer">
              <Image src={instagram} fluid alt="icono" />
            </a>
      </div>
    </div>
  );
}
