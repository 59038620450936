import { Image, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import disfruta from "../assets/img/disfrutayvivi.jpg";
import reconectar from "../assets/img/espaciopararecordar.png";
import comidas from "../assets/img/comidagourmet.png";

export default function BannerCajas() {
  return (
    <div className="">
      {/* caja #1 */}
      <div className="my-lg-5 my-0 container">
        <div className="row">
          <div className="col-lg-6 col-sm-12 p-5 d-flex flex-column justify-content-center text-center">
            <p className="peso-italic color-negro tamaño-mas-grande interlineado2 d-none d-lg-block">
              Disfruta y viví <br />
              la experiencia Tafí <br />
              desde el centro de la Villa
            </p>
            <p className="peso-italic color-negro tamaño-grande d-lg-none d-block">
              Disfruta y viví <br />
              la experiencia Tafí <br />
              desde el centro de la Villa
            </p>
            <p className="d-none d-xl-block mx-3">
              Imagina despertar en un lugar paradisíaco, abrir la ventana y ver
              la majestuosidad del cerro, caminar 50 metros y encontrarte en el
              corazón de la villa listo disfruta de las actividades que tenemos
              para vos y tu familia.
            </p>
            <p className="tamaño-chico d-xl-none d-block">
              Imagina despertar en un lugar paradisíaco, abrir la ventana y ver
              la majestuosidad del cerro, caminar 50 metros y encontrarte en el
              corazón de la villa listo disfruta de las actividades que tenemos
              para vos y tu familia.
            </p>
            <div>
              <NavLink to="/Descubre">
                <Button variant="outline-secondary tamaño-medio peso-medium">
                  Ver mas
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 text-center d-flex align-items-center justify-content-center">
            <Image
              className="caja-imagen-banner"
              src={disfruta}
              alt="ImagenDisfruta"
              fluid
            />
          </div>
        </div>
      </div>
      {/* caja #2 */}
      <div className="color-blanco-oscuro-fondo py-5 my-5">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-sm-12 d-none d-lg-block text-center d-flex align-items-center justify-content-center">
              <Image
                className="caja-imagen-banner"
                src={reconectar}
                alt="Habitaciones"
                fluid
              />
            </div>

            <div className="col-lg-6 col-sm-12 pt-lg-5 pt-0 px-5 pb-5  d-flex flex-column justify-content-center text-center">
              <p
                className="color-blanco-fondo peso-medium tamaño-grande mx-5 py-4"
                style={{ zIndex: 2 }}
              >
                Nuestras Habitaciones
              </p>
              <hr
                className="color-gris-claro-fondo d-none d-lg-block"
                style={{ marginTop: "-65px", height: "2px" }}
              />

              <p className="tamaño-mas-grande peso-italic color-negro pt-5 interlineado2 d-none d-lg-block">
                Un espacio <br />
                para reconectar
              </p>
              <p className="peso-italic color-negro tamaño-grande d-lg-none d-block">
                Un espacio <br />
                para reconectar
              </p>
              <p className="d-none d-lg-block">
                Habitaciones confortables dobles y triples con estilo y calidez.
              </p>
              <p className="tamaño-chico d-lg-none d-block">
                Habitaciones confortables dobles y triples con estilo y calidez.
              </p>
              <div>
                <NavLink to="/Habitaciones">
                  <Button
                    className="peso-medium color-blanco-fondo tamaño-medio"
                    variant="outline-secondary"
                  >
                    Ver mas
                  </Button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 d-lg-none d-block text-center">
              <Image
                className="caja-imagen-banner "
                src={reconectar}
                alt="Habitaciones"
                fluid
              />
            </div>
          </div>
        </div>
      </div>
      {/* caja #3 */}
      <div className="my-5 container">
        <div className="row ">
          <div className="col-lg-6 col-sm-12 pt-lg-5 pt-0 px-5 pb-5 d-flex flex-column justify-content-center text-center">
            <p
              className="color-blanco-oscuro-fondo peso-medium tamaño-grande mx-5 py-4"
              style={{ zIndex: 2 }}
            >
              Sabores regionales
            </p>
            <hr
              className="color-gris-claro-fondo d-none d-lg-block"
              style={{ marginTop: "-65px", height: "2px" }}
            />
            <p className="tamaño-mas-grande peso-italic color-negro pt-5 interlineado2 d-none d-lg-block">
              Disfruta nuestras <br />
              delicias gourmet <br />
            </p>
            <p className="peso-italic color-negro tamaño-grande d-lg-none d-block">
              Disfruta nuestras <br />
              delicias gourmet <br />
            </p>
            <p className="d-none d-lg-block">
              Descubrí en nuestra carta la combinación perfecta de elaborados
              platos regionales y tradicionales con una exquisita selección de
              vinos locales y nacionales.
            </p>
            <p className="tamaño-chico d-lg-none d-block">
              Descubrí en nuestra carta la combinación perfecta de elaborados
              platos regionales y tradicionales con una exquisita selección de
              vinos locales y nacionales.
            </p>
            <div>
              <NavLink to="/Sabores">
                <Button
                  className="peso-medium color-blanco-fondo tamaño-medio"
                  variant="outline-secondary"
                >
                  Ver mas
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 text-center">
            <Image
              className="caja-imagen-banner "
              src={comidas}
              alt="Sabores"
              fluid
            />
          </div>
        </div>
      </div>
    </div>
  );
}
