import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import carousel2 from "../assets/img/homecarousel2.png";
import carousel2cel from "../assets/img/homecarouselcel2.png";

export default function BannerDescubre() {
  return (
    <Carousel fade indicators={false} controls={false}>
      <Carousel.Item className="banner-tam d-flex align-items-center justify-content-center">
        <Image
          className="d-none d-sm-block"
          src={carousel2}
          alt="banner"
          fluid
        />
        <Image
          className="d-sm-none d-block"
          src={carousel2cel}
          alt="banner"
          fluid
        />
      </Carousel.Item>
    </Carousel>
  );
}
