import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import reserva from "../assets/img/reserva.png";

export default function CardReserva() {
  return (
    <div>
      <div className="card-reserva my-5 d-none d-lg-block">
        <div className="d-flex justify-content-between color-blanco-oscuro-fondo">
          <Image className="" src={reserva} alt="reserva" fluid />
          <div className="d-flex flex-column justify-content-center text-center">
            <p className="peso-italic tamaño-mas-grande color-negro">
              Reserva Online
            </p>
            <p className="mx-5">
              Obtené beneficios exclusivos al reservar en nuestra pagina web
            </p>
            <Link to="/Reserva">
              <Button className="color-negro-fondo boton-reserva">
                <p className="text-white mb-0 peso-medium tamaño-medio mx-2">
                  ¡QUIERO RESERVAR!
                </p>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="my-5 py-5 d-lg-none d-block color-blanco-oscuro-fondo">
        <div className="d-flex flex-column justify-content-center text-center">
          <p className="peso-italic tamaño-grande color-negro">
            Reserva Online
          </p>
          <p className="mx-5 tamaño-chico">
            Obtené beneficios exclusivos al reservar en nuestra pagina web
          </p>
          <Link to="/Reserva">
            <Button className="color-negro-fondo boton-reserva">
              <p className="text-white mb-0 peso-medium mx-2">
                ¡QUIERO RESERVAR!
              </p>
            </Button>
          </Link>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Image className="w-50" src={reserva} alt="reserva" fluid />
        </div>
      </div>
    </div>
  );
}
