import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {EffectCoverflow,Keyboard, Pagination, Navigation } from "swiper";
import ComentarioExcelCard from "./ComentarioExcelCard";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function ComentariosExcel() {
  const { width } = useWindowDimensions();
  const [comentarios, setComentarios] = useState([]);

  useEffect(() => {
    const getComentarios = async () => {
      const response = await axios.get(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vT6uYs8LSFy65QbaODsfV6iy6vYPiy2qD8gn67FmxZJwDFig85XgO4RSc9QHKanw5jiwZNsw84fx8vZ/pub?gid=1863850417&single=true&output=csv"
      );

      const articulos = Papa.parse(response.data, { header: true });

      setComentarios(articulos.data);
    };
    getComentarios();
  }, []);
  return (
    <div>
      <div className="text-center my-5 container d-lg-block d-none">
        <p className="tamaño-mas-grande peso-italic color-negro">
          Opiniones de huéspedes
        </p>
      </div>
      <div className="text-center my-5 container d-block d-lg-none">
        <p className="tamaño-grande peso-italic color-negro">
          Opiniones de huéspedes
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-between pb-5 cursor">
        <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={width >= 770 ? width / 475 : width / 400}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        modules={[EffectCoverflow, Pagination,Navigation,Keyboard]}
          className="mySwiper pb-5"
        
        >
          {comentarios.map((comentario, id) => (
            <SwiperSlide className="d-flex flex-column">
              <ComentarioExcelCard key={id} data={comentario} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
