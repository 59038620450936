import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import visitantes from "../assets/img/visitantes.jpg";
import habitaciones from "../assets/img/habitaciones.jpg";
import eventos from "../assets/img/eventos.png";

export default function CardsVisitantes() {
  return (
    <div>
      <div className=" my-5 py-5 color-blanco-oscuro-fondo d-none d-lg-block">
        <div className="container d-flex ">
          <div>
            <Image className="px-3" src={visitantes} alt="visitantes" fluid />
            <Link to="/Visitantes">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Visitantes
                </p>
              </div>
            </Link>
          </div>
          <div>
            <Image
              className="px-3"
              src={habitaciones}
              alt="habitaciones"
              fluid
            />
            <Link to="/Habitaciones">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Habitaciones
                </p>
              </div>
            </Link>
          </div>
          <div>
            <Image className="px-3" src={eventos} alt="eventos" fluid />
            <Link to="/Eventos">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Eventos
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className=" my-5 py-4 color-blanco-oscuro-fondo d-lg-none d-block">
        <div className="container d-flex flex-wrap justify-content-center">
          <div className="my-4">
            <Image className="px-3" src={visitantes} alt="visitantes" fluid />
            <Link to="/Visitantes">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium text-center color-gris caja-card py-3">
                  Visitantes
                </p>
              </div>
            </Link>
          </div>
          <div className="my-4">
            <Image
              className="px-3"
              src={habitaciones}
              alt="habitaciones"
              fluid
            />
            <Link to="/Habitaciones">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium text-center color-gris caja-card py-3">
                  Habitaciones
                </p>
              </div>
            </Link>
          </div>
          <div className="my-4">
            <Image className="px-3" src={eventos} alt="eventos" fluid />
            <Link to="/Eventos">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium text-center color-gris caja-card py-3">
                  Eventos
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
