import VentajasReserva from "../components/VentajasReserva";
import NavReact from "../components/NavReact";

export default function Reserva() {
    return (
        <div>
            <NavReact />
            <div className="container">
                <VentajasReserva />
            </div>
        </div>
    );
}
