import BannerHabitaciones from "../components/BannerHabitaciones";
import CardReserva from "../components/CardReserva";
import HabitacionesTipo from "../components/HabitacionesTipo";
import NavReact from "../components/NavReact";

export default function Habitaciones() {
    return (
        <div>
            <BannerHabitaciones />
            <NavReact />
            <HabitacionesTipo />
            <CardReserva />
        </div>
    );
}
