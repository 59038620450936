import { Image } from "react-bootstrap";
import { useState } from "react";
import btn from "../assets/img/botonReservaNew.png";

export default function ReservaBoton() {
  const [navbarPosition, setNavbarPosition] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 450) {
      setNavbarPosition(true);
    } else {
      setNavbarPosition(false);
    }
    // console.log(window.scrollY); //Para ver en el medidor de scroll
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <div className="d-flex justify-content-center">
      <div className="navbar-bordes">
        <a
          className=""
          href="https://wubook.net/neb/bk?f=today&n=1&ep=710b23c4&board=bb&lang=es&jr=%5B%7B%22occupancy%22%3A%7B%22adults%22%3A2%2C%22children%22%3A0%2C%22teens%22%3A0%2C%22babies%22%3A0%7D%7D%5D"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className={
              navbarPosition
                ? "icono-reserva fixed-top ms-auto margen-reserva me-2"
                : "icono-reserva fixed-top ms-auto me-2 margen-reserva2"
            }
            src={btn}
            alt="wsp"
            fluid
          />
        </a>
      </div>
    </div>
  );
}
