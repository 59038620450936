import React from "react";
import EventosExcel from "../components/EventosExcel";
import NavReact from "../components/NavReact";

export default function Eventos() {
    return (
        <div>
            <NavReact />
            <EventosExcel />
        </div>
    );
}
