import React from "react";
import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import estandar from "../assets/img/estandar.jpg";
import confort from "../assets/img/confort.jpg";
import superior from "../assets/img/superior.jpg";

export default function HabitacionesTipo() {
  return (
    <div className="my-4">
      <div className="container text-center">
        <p className=" mt-5 tamaño-mas-grande color-negro peso-italic d-none d-lg-block">
          {" "}
          ¿Cómo sería tu estancia ideal en nuestro hotel? Esa es la pregunta, y
          nosotros tenemos la respuesta.
        </p>
        <p className=" tamaño-grande color-negro peso-italic d-lg-none d-block">
          {" "}
          ¿Cómo sería tu estancia ideal en nuestro hotel? Esa es la pregunta, y
          nosotros tenemos la respuesta.
        </p>
        <p className="d-none d-lg-block">
          {" "}
          Contamos con habitaciones dobles y triples, confortables, cálidas y
          con una estilo único, para que te sientas como en casa. Elegí tu
          habitación perfecta para descansar en familia o con amigos. Totalmente
          equipas para tu mayor comodidad con tv por cable, wifi y baño privado.
        </p>
        <p className="tamaño-chico d-lg-none d-block">
          Contamos con habitaciones dobles y triples, confortables, cálidas y
          con una estilo único, para que te sientas como en casa. Elegí tu
          habitación perfecta para descansar en familia o con amigos. Totalmente
          equipas para tu mayor comodidad con tv por cable, wifi y baño privado.
        </p>
      </div>
      <div className=" my-5 py-5 d-none d-lg-block">
        <div className="container d-flex ">
          <div>
            <Image className="px-3" src={estandar} alt="Estandar" fluid />
            <NavLink to="/Habitaciones/Estandar">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Estandar
                </p>
              </div>
            </NavLink>
          </div>
          <div>
            <Image className="px-3" src={confort} alt="Confort" fluid />
            <NavLink to="/Habitaciones/Confort">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Confort
                </p>
              </div>
            </NavLink>
          </div>
          <div>
            <Image className="px-3" src={superior} alt="Superior" fluid />
            <NavLink to="/Habitaciones/Superior">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Superior
                </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className=" my-4 pb-4 d-lg-none d-block">
        <div className="container d-flex flex-wrap justify-content-center">
          <div className="my-4">
            <Image
              className="px-3"
              src="https://res.cloudinary.com/dw5inqpkm/image/upload/v1676061838/media-pagina-web%20/Reducidas/habitacionestandar.9b575b792f927e2af2c9_hbw4sw_vjhwzl.jpg"
              alt="Estandar"
              fluid
            />
            <NavLink to="/Habitaciones/Estandar">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium text-center color-gris caja-card py-3">
                  Estandar
                </p>
              </div>
            </NavLink>
          </div>
          <div className="my-4">
            <Image
              className="px-3"
              src="https://res.cloudinary.com/ddhhafebk/image/upload/v1675976284/media-pagina-web/habitacionconfort.05ca8bd6a8647eab50bb_dvzlvq_wjvzga.jpg"
              alt="Confort"
              fluid
            />
            <NavLink to="/Habitaciones/Confort">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium text-center color-gris caja-card py-3">
                  Confort
                </p>
              </div>
            </NavLink>
          </div>
          <div>
            <Image
              className="px-3"
              src="https://res.cloudinary.com/dw5inqpkm/image/upload/v1676061837/media-pagina-web%20/Reducidas/habitacionsuperior.f59396640e555947f480_jbphoi_dwl7d7.jpg"
              alt="Superior"
              fluid
            />
            <NavLink to="/Habitaciones/Superior">
              <div className="d-flex justify-content-center">
                <p className="color-blanco-fondo peso-medium tamaño-grande text-center color-gris caja-card py-3">
                  Superior
                </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <p className=" tamaño-mas-grande color-negro peso-italic d-none d-lg-block">
          {" "}
          No postergues más ese descanso que tanto te merecés
        </p>
        <p className=" tamaño-grande color-negro peso-italic d-lg-none d-block">
          {" "}
          No postergues más ese descanso que tanto te merecés
        </p>
      </div>
    </div>
  );
}
