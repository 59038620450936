import { Image } from "react-bootstrap";
import vivi from "../assets/img/vivitafi.jpg";

export default function ViviTafi() {
  return (
    <div className="my-lg-5 my-0 color-blanco-fondo container">
      <div className="row">
        <div className="col-lg-6 col-sm-12 p-5 d-flex flex-column justify-content-center text-center">
          <hr className="color-negro-fondo d-none d-sm-block mx-5" />

          <p className="tamaño-mas-grande peso-italic color-negro pt-5 d-none d-lg-block">
            Viví Tafí
          </p>
          <p className="d-none d-lg-block">
            Nuestro Hotel es más que un lugar para descansar, cada rincón está
            diseñado para regalarte una estadía llena de momentos únicos que
            deleitan tus sentidos. Regálate un tiempo para vos en Hotel Tafí.
            Vení y enamorate.
          </p>
          <p className="tamaño-grande peso-italic color-negro d-lg-none d-block">
            Vivi Tafi
          </p>
          <p className="tamaño-chico  d-lg-none d-block">
            Nuestro Hotel es más que un lugar para descansar, cada rincón está
            diseñado para regalarte una estadía llena de momentos únicos que
            deleitan tus sentidos. Regálate un tiempo para vos en Hotel Tafí.
            Vení y enamorate.
          </p>
        </div>
        <div className="col-lg-6 col-sm-12 my-auto">
          <div className="d-flex justify-content-center ">
            <Image
              className="caja-imagen-banner2"
              src={vivi}
              fluid
              alt="Tafi"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
